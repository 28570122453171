<template>
    <div
      class="bg-q-primary overflow-auto py-4 py-lg-0"
      style="position: fixed; height: 100%; width: 100%; right: 0%">
      <div class="row">
        <div class="col-lg-9 px-4">
          <div
            class="lg-padding mb-5 pb-3 d-flex justify-content-center justify-content-lg-start"
            style="width: 136px">
            <img
              src="https://app.quizell.com/logo/logo.png"
              style="width: 136px" />
          </div>
          <div class="d-flex justify-content-center d-lg-none">
            <div class="">
              <img
                src="https://images.quizell.com/website/verifyWixUserClient.png"
                class="mob-img"
                lazy
                alt="" />
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-center">
            <div class="d-flex flex-column lg-padding" style="gap: 50px">
              <div class="q-heading">
                Congrats, your account have been successfully upgraded!
              </div>
              <div class="w-100">
                <div class="q-info">
                  You’ll be redirected to your account automatically in {{ redirectTimer }} seconds
                </div>
              </div>
              <div class="">
                <router-link to="/" class="navigate"
                  >Go to your account now</router-link
                >
              </div>
            </div>
            <div class="d-none d-lg-block levitate" style="z-index: 1">
              <img
                src="https://images.quizell.com/website/verifyWixUserClient.png"
                lazy
                alt="" />
            </div>
          </div>
        </div>
        <div
          class="col-lg-3 d-none d-lg-block bg-q-secondary"
          style="height: 100vh">
          <div class="d-flex justify-content-end">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="168"
              height="172"
              viewBox="0 0 168 172"
              fill="none">
              <path
                d="M256.911 93.0851C268.363 100.815 276.578 106.424 281.557 109.915C290.519 116.647 295 123.13 295 129.363C295 135.348 291.266 143.825 283.797 154.796C276.329 165.767 268.736 171.252 261.019 171.252C256.289 171.252 249.07 167.886 239.361 161.154C238.116 160.406 234.631 158.037 228.905 154.048C223.179 150.059 218.698 147.066 215.462 145.072C192.31 163.024 166.295 172 137.418 172C98.8312 172 66.2193 158.287 39.5822 130.86C13.1941 103.433 0 70.7694 0 32.8702C0 12.1749 3.73419 -7.14849 11.2025 -25.1005C18.6709 -43.3026 28.6287 -58.5119 41.0759 -70.7294C53.5232 -82.9469 67.8376 -92.5465 84.019 -99.5276C100.2 -106.509 116.88 -110 134.057 -110C170.901 -110 202.89 -96.6606 230.025 -69.9813C257.16 -43.3026 270.728 -9.51692 270.728 31.374C270.728 52.0693 266.122 72.6393 256.911 93.0851ZM66.0947 31.0003C66.0947 53.1912 73.1899 71.2679 87.3797 85.2312C101.819 98.9443 117.876 105.801 135.551 105.801C153.225 105.801 169.158 99.069 183.348 85.6049C197.538 72.1407 204.633 54.064 204.633 31.374C204.633 8.68462 197.413 -9.51692 182.974 -23.2306C168.784 -36.9443 152.852 -43.8011 135.177 -43.8011C117.502 -43.8011 101.57 -36.8195 87.3797 -22.8568C73.1899 -9.14315 66.0947 8.80885 66.0947 31.0003Z"
                fill="#5C2593"
                fill-opacity="0.06" />
            </svg>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
      data(){
          return {
              redirectTimer:10,
          }
      },
      mounted(){
          const timer = setInterval(()=>{
              this.redirectTimer--;
              if(this.redirectTimer==0)
              {
                  clearInterval(timer);
                  this.$router.push('/')
              }
          },1000)
      }
  };
  </script>
  
  <style scoped>
  * {
    --primaryColor: #4d1b7e;
    --secondaryColor: #fefeff;
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
  }
  .bg-q-secondary {
    background: var(--secondaryColor) !important;
  }
  .navigate {
    width: 100%;
    display: block;
    text-decoration: none;
    padding: 15px 16%;
    color: var(--primaryColor);
    background: var(--secondaryColor);
    border-radius: 10px;
    max-width: 400px;
    text-align: center;
    font-family: Poppins;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }
  .levitate img {
    width: 460px;
  }
  @keyframes levitateAnimation {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(
        -10px
      ); /* Adjust the value to control the amount of levitation */
    }
  }
  .levitate {
    position: absolute;
    right: -238px;
    animation: levitateAnimation 2s infinite alternate ease-in-out;
  }
  .bg-q-primary {
    background: var(--primaryColor);
    color: #fff;
  }
  .q-heading {
    font-size: 28px;
  
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    max-width: 660px;
  }
  .q-info {
    max-width: 436px;
    font-family: Poppins;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }
  @media (min-width: 992px) {
    .lg-padding {
      padding: 0px 160px;
    }
    .q-heading {
      font-size: 45px;
    }
  }
  /* Adjust image size using media queries for .mob-img class */
  .mob-img {
    max-width: 100%; /* Ensures image won't exceed its parent container */
    height: auto; /* Allows the image to maintain its aspect ratio */
  
    /* Define the default size for larger screens */
    width: 355px;
  }
  
  /* Media queries to adjust .mob-img size for different screen sizes */
  @media (max-width: 990px) {
    .mob-img {
      width: 70%; /* Adjust as needed for smaller screens */
    }
  }
  
  /* Additional media queries for smaller screens if necessary */
  @media (max-width: 480px) {
    .mob-img {
      width: 90%; /* Adjust as needed for even smaller screens */
    }
  }
  </style>
  